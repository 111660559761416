<template>
    <div>
        <div class="user">
            <van-image
                class="user-poster"
                src="https://file4.mogody.com/avatars/account_bg.png"
            />
            <div class="user-info">
                <van-image
                    round
                    class="user-avatar"
                    :src="userInfo.avatar"
                    @click="handleAvatarClick"
                />
                <p class="user-nickname">@ {{ userInfo.nickname }}</p>
                <p class="user-bio">{{ userInfo.bio }}</p>
            </div>
        </div>

        <van-row class="user-links">
            <van-col span="8" @click="handleRedirect('/sermon')">
                <van-icon name="description" />
                讲道记录
            </van-col>
            <van-col span="8" @click="handleRedirect('/user/collect')">
                <van-icon name="star-o" />
                我的收藏
            </van-col>
            <van-col span="8" @click="handleRedirect('/note')">
                <van-icon name="records" />
                听道笔记
            </van-col>
        </van-row>
        <van-cell-group class="action-group">
            <van-cell icon="newspaper-o" title="阅读" @click="handleRedirect('/reader', false)"/>
            <van-cell icon="orders-o" title="圣经" @click="handleRedirect('/bible', false)"/>
            <van-cell icon="music-o" title="赞美诗" @click="handleRedirect('/poem', false)"/>
            <van-cell icon="video-o" title="直播回放" @click="handleRedirect('/playback', false)"/>
        </van-cell-group>

        <van-cell-group class="action-group" v-if="user && !user.phone">
            <van-cell icon="phone-o" title="绑定手机号" @click="handleRedirect('/bind', false)"/>
        </van-cell-group>

        <van-cell-group class="action-group logout" v-if="this.user !== null">
            <van-cell @click="() => this.logoutCheck = true" title="退出登录"/>
        </van-cell-group>
        <van-action-sheet
            @select="handLogout"
            :round="false"
            v-model="logoutCheck"
            :actions="actions"
            cancel-text="取消"
            description="确定退出登录吗?"
            @cancel="() => this.logoutCheck = false"
        ></van-action-sheet>
        <tabbar />
    </div>
</template>

<script>
import { LOGIN_USER, ACCESS_TOKEN } from '@/utils/constants'
import Tabbar from '@/components/Tabbar'

export default {
    name: 'UserIndex',
    methods: {
        handleRedirect (path, needLogin = true) {
            if (needLogin && this.user === null) {
                this.$notify({ type: 'warning', message: '请先登录' })
                return
            }
            this.$router.push({ path })
        },
        handleBulletin () {
            this.$notify({ type: 'warning', message: '暂无内容.' });
        },
        handleAvatarClick () {
            if (this.user === null) {
                this.$router.push({ path: '/login' })
            } else {
                this.$router.push({ path: '/user/edit' })
            }
        },
        handLogout () {
            this.user = this.logoutCheck = null
            this.$ls.remove(ACCESS_TOKEN)
            this.$ls.remove(LOGIN_USER)
        }
    },
    data () {
        return {
            user: {},
            logoutCheck: false,
            actions: [
                { name: '确定' },
            ]
        }
    },
    computed: {
        userInfo () {
            if (this.user === null) {
                return {
                    nickname: '点击头像登录',
                    avatar: 'https://file4.mogody.com/avatars/avatar-default.png'
                }
            }
            return this.user
        }
    },
    components: { Tabbar },
    created () {
        this.user = this.$ls.get(LOGIN_USER)
    }
};
</script>

<style lang="less">
    .action-group {
        margin-top: 10px;
    }
    .logout {
        .van-cell__title {
            text-align: center;
            color: #ff0000;
        }
    }
    .user {
        position: relative;
        &-poster {
            width: 100%;
            display: block;
            background: #2d8cf0;
        }
        &-info {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        &-avatar {
            width: 60px;
            height: 60px;
        }
        &-nickname {
            color: #fff;
            font-size: 14px;
        }
        &-bio {
            color: #fff;
            font-size: 12px;
            margin: 0px;
        }
        &-links {
            padding: 15px 0;
            font-size: 12px;
            text-align: center;
            background-color: #fff;

            .van-icon {
                display: block;
                font-size: 24px;
            }
        }
    }
</style>
