<template>
    <div style="height: 60px">
        <van-tabbar v-model="active" route>
            <van-tabbar-item name="video" icon="video-o" to="/video" v-if="openWidgets.includes('video')">视频</van-tabbar-item>
            <van-tabbar-item name="reader" icon="newspaper-o" to="/reader" v-if="openWidgets.includes('reader')">阅读</van-tabbar-item>
            <van-tabbar-item name="audio" icon="service-o" to="/audio" v-if="openWidgets.includes('audio')">音频</van-tabbar-item>
            <van-tabbar-item name="music" icon="music-o" to="/poem" v-if="openWidgets.includes('music')">诗颂</van-tabbar-item>
            <van-tabbar-item name="live" icon="tv-o" :to="'/' + channel.code" v-if="openWidgets.includes('live')">直播</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
import { getLiveChannel } from '@/api/live'
export default {
    name: "Tabbar",
    data() {
        return {
            active: 'video',
            channel: {
                code: ''
            },
        }
    },
    computed: {
        openWidgets () {
            return process.env.VUE_APP_OPEN_WIDGETS.split(',')
        }
    },
    created() {
        getLiveChannel().then(res => {
            this.channel = res.data
        })
    }
}
</script>

<style scoped>

</style>
